<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Notice</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="noticelist"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <span style="width: 20px"></span>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="iseditable = !iseditable"
                v-on="on"
                >Add Notice</v-btn
              >
            </template>
            <v-card>
              <v-form v-model="valid">
                <v-card-title>
                  <v-row justify="space-between">
                    <span class="headline">{{ formTitle }}</span>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-col cols="12" sm="12" md="12" v-if="editedIndex == -1">
                      <v-textarea label="Notice" v-model="editedItem.notice">
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="editedIndex != -1">
                      <v-textarea v-model="editedItem.notice" label="Notice">
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="editedIndex == -1">
                      <v-text-field
                        type="number"
                        label="No_of_days"
                        v-model="editedItem.no_of_days"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="editedIndex != -1">
                      <v-text-field
                        type="number"
                        v-model="editedItem.no_of_days"
                        label="No_of_days"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="editedIndex == -1">
                      <v-autocomplete
                        class="pt-2 pb-1"
                        :items="program_list"
                        v-model="editedItem.program"
                        label="Select Program"
                        persistent-hint
                        item-value="id"
                        item-text="name"
                        :hint="
                          editedIndex == -1
                            ? 'For example ::M.Tech,B.Tech'
                            : 'For example :::M.Tech,B.Tech'
                        "
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" v-if="editedIndex != -1"
                      >Program
                      <v-select
                        dense
                        class="pt-2 pb-1"
                        v-model="editedItem.program"
                        :items="program_list"
                        item-value="id"
                        persistent-hint
                        item-text="name"
                        :hint="
                          editedIndex != -1
                            ? 'For example ::M.Tech,B.Tech'
                            : 'For example :::M.Tech,B.Tech'
                        "
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" v-if="editedIndex == -1">
                      <v-autocomplete
                        class="pt-2 pb-1"
                        :items="year_list"
                        v-model="editedItem.year"
                        label="Select Year"
                        persistent-hint
                        item-value="id"
                        item-text="name"
                        :hint="
                          editedIndex == -1
                            ? 'For example ::FE, TE'
                            : 'For example ::FE,TE'
                        "
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" v-if="editedIndex != -1"
                      >Year
                      <v-select
                        dense
                        v-model="editedItem.year"
                        :items="year_list"
                        item-value="id"
                        persistent-hint
                        item-text="name"
                        :hint="
                          editedIndex != -1
                            ? 'For example ::FE,TE,BTech'
                            : 'For example ::BTech,TE,FE'
                        "
                      ></v-select>
                    </v-col>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary darken-1"
                    dark
                    @click="save"
                    :disabled="!valid"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)"
              >edit</v-icon
            >
          </v-avatar>
          <v-btn text>
            <v-icon color="red" @click="deletefunc(item.id)">
              mdi-delete</v-icon
            ></v-btn
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    search: "",
    dialog: false,
    valid: false,
    iseditable: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    year_list: "",
    program_list: "",
    noticelist: "",
    program: "",
    year: "",
    notice: "",
    errorMsg: {
      id: false,
    },

    headers: [
      {
        text: "Notice",
        align: "left",
        sortable: false,
        value: "notice",
      },
      {
        text: "Program",
        align: "left",
        sortable: false,
        value: "program",
      },
      {
        text: "Year",
        align: "left",
        sortable: false,
        value: "year",
      },
      {
        text: "No. Of days",
        align: "left",
        sortable: false,
        value: "no_of_days",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      program: "",
      year: "",
      notice: "",
      no_of_days: "",
    },
    defaultItem: {
      id: "",
      program: "",
      year: "",
      notice: "",
      no_of_days: "",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      //  this.overlay = true;
      axios.get("/AdminMaster/addCommonNoticeMessage").then((res) => {
        if (res.data.msg == "200") {
          this.year_list = res.data.year_list;
          this.program_list = res.data.program_list;
          this.noticelist = res.data.noticelist;
          this.year_list.push({
            name: "All",
            id: "All",
          });
          this.editedItem.year = "All";
          this.program_list.push({
            name: "All",
            id: "All",
          });
          this.editedItem.program = "All";
        }
      });
    },

    save() {
      //  alert("updated"+this.editedItem.id)
      if (this.editedIndex > -1) {
        // if (this.isValidated())
        //  {
        axios
          .post("/AdminMaster/editCommonNoticeMessage", this.editedItem)
          .then((res) => {
            if (res.data.msg == "200") {
              this.showSnackbar("#4caf50", "Setting Updated Successfully..."); // show snackbar on success
              this.editedItem.year = "";
              this.editedItem.program = "";
              this.editedItem.no_of_days = "";
              this.editedItem.notice = "";
              this.onLoad();
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", "Setting Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });

        this.close();
        // }
      } else {
        axios
          .post("/AdminMaster/saveCommonNoticeMessage", this.editedItem)
          .then((res) => {
            if (res.data.msg == "200") {
              //  this.setting_list=res.data.setting_list

              this.showSnackbar("#4caf50", " Added Successfully..."); // show snackbar on success
              this.onLoad();
              this.editedItem.year = "";
              this.editedItem.program = "";
              this.editedItem.no_of_days = "";
              this.editedItem.notice = "";
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", " Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    }, // end of save()
    deletefunc(item) {
      // console.log("a")
      const data = {
        deleteitem: item,
      };
      // console.log("a1")
      // if(this.editedItem.setting_master){
      axios
        .post("/AdminMaster/deleteCommonNoticeMessage", data)
        .then((res) => {
          //console.log("a2")
          if (res.data.msg == "200") {
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onLoad();
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
      // }
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem(item) {
      // console.log("item");
      // console.log(item.id);
      this.iseditable = false;
      this.editedIndex = this.noticelist.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.id = item.id;
      this.editedItem.program = item.program_id;
      this.editedItem.year = item.year_id;

      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
